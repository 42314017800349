<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" centered :title="modalTitle">
        <b-form @submit.prevent="confirm">
            <b-form-group label="Nom">
                <b-form-input v-model="form.nom" type="text"/>
            </b-form-group>

            <roles-popover-wiki/>
            <b-form-group>
                <label slot="label" id="popover-roles">
                    Visible par
                    <font-awesome-icon icon="question-circle"/>
                </label>
                <multi-select v-model="form.visible"
                              :options="rolesAvailable"
                              :multiple="false"
                              :close-on-select="true"
                              track-by="id"
                              :clear-on-select="false"
                              :preserve-search="false"
                              label="nom"/>
            </b-form-group>

            <!-- This choice has no sense for other users : they would have only one option -->
            <b-form-group v-if="isBureau()" label="Éditable par">
                <multi-select v-model="form.editable"
                              :options="rolesAvailableEdit"
                              :multiple="false"
                              :close-on-select="true"
                              track-by="id"
                              :clear-on-select="false"
                              :preserve-search="false"
                              label="nom"/>
            </b-form-group>

            <confirm-cancel-buttons @confirm="confirm" @cancel="hideModal"/>
        </b-form>
    </b-modal>
</template>

<script>
    import modalMixin            from '@/mixin/modalMixin';
    import auth                  from '@/util/auth';
    import {checkNonEmptyFields} from '@/util/form';
    import {apiPath}             from '@/util/http';
    import alert                 from '@/util/alert';
    import wiki                  from '@/util/wiki';

    const RolesPopoverWiki     = () => import('@/components/wiki/RolesPopoverWiki');
    const ConfirmCancelButtons = () => import('@/components/ConfirmCancelButtons');
    const MultiSelect          = () => import('@/components/MultiSelect');

    export default {
        name: "EditFolderModal",
        components: {RolesPopoverWiki, ConfirmCancelButtons, MultiSelect},
        mixins: [modalMixin],
        props: {
            parent: {
                type: Object,
                default: null
            },
            article: {
                type: Object,
                default: null
            },
            callback: {
                type: Function,
                default: () => 0
            }
        },
        data: () => ({
            modalRef: 'EditArticleModal',
            articleMetadata: null,
            form: {
                parent: null,
                nom: null,
                visible: null,
                editable: wiki.getRoleSelectOption('ROLE_CLUJI')
            },
            rolesAvailable: wiki.ROLES_AVAILABLE,
            rolesAvailableEdit: wiki.ROLES_AVAILABLE_EDIT
        }),
        computed: {
            modalTitle() {
                return this.article ? this.article.nom : 'Nouvel article';
            }
        },
        methods: {
            isBureau: auth.isBureau,
            confirm() {
                if (!checkNonEmptyFields(this.form, ['nom', 'visible', 'editable'])) {
                    this.$toaster.error('Veuillez remplir tous les champs');
                    return;
                }

                alert.loading();
                this.axios
                    .post(apiPath('wiki_article_edit', this.article ? {article: this.article.id} : {}), {
                        ...this.form,
                        visible: this.form.visible.id, // Extract only the ID to send to Symfony
                        editable: this.form.editable.id // Same
                    })
                    .then(() => this.callback())
                    .catch(() => this.$toaster.error("Impossible de créer l'article"))
                    .finally(alert.stopLoading);

                this.hideModal();
            },
            isArticleHydrated() {
                return this.articleMetadata && this.articleMetadata.parent;
            },
            fetchFullArticle() {
                return this.axios.get(apiPath('wiki_article_get_metadata', {article: this.article.id}))
                    .then(response => this.articleMetadata = response.data)
                    .catch(() => this.$toaster("Les données de l'article n'ont pas pu être récupérées"));
            },
            hydrateFormFromArticle() {
                this.form = {
                    ...this.articleMetadata,
                    parent: this.articleMetadata.parent.id, // We only want the ID in the form, not the complete object
                    visible: wiki.getRoleSelectOption(this.articleMetadata.visible),
                    editable: wiki.getRoleSelectOption(this.articleMetadata.editable)
                };
            },
            hydrateFormFromParent() {
                this.form.parent   = this.parent.id;
                // By default, apply the same visibility as the parent folder
                this.form.visible  = wiki.getRoleSelectOption(this.parent.visible);
                // By default, restrict edit to "ROLE_BUREAU" if the parent folder is only visible to them
                this.form.editable = this.form.visible.id === 'ROLE_BUREAU'
                    ? this.form.visible
                    : wiki.getRoleSelectOption('ROLE_CLUJI');
            }
        },
        mounted() {
            // If the aricle already exists, fill the form with its infos
            if (this.article) {
                this.articleMetadata = {...this.article};
                if (this.isArticleHydrated())
                    this.hydrateFormFromArticle();
                else
                    this.fetchFullArticle().then(() => this.hydrateFormFromArticle());

                return;
            }

            // If the article doesn't already exist, only put default values from parent folder
            if (this.parent) {
                this.hydrateFormFromParent();

                return;
            }

            // Article should either already exist or have a parent folder, you can't have both of them NULL
            this.$toaster.error('Créer un article sans dossier parent est interdit');
            this.hideModal();
        }
    }
</script>
